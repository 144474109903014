<script setup>
import Layout from "../../layouts/main.vue";
import mushroom from "cem-primary-api";
import { Filter } from "mushroomjs";
import MapComponent from "../../components/MapComponent.vue";
import { onMounted, reactive, ref, watch, computed } from "vue";
import MethodService from "../../service/MethodService";
import { FormInstance } from "element-plus";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { Location } from "@element-plus/icons-vue";
const ruleFormRef = ref(FormInstance);
import toastr from "toastr";
import { useRoute, useRouter } from "vue-router";
import Validate from "../../service/ValidService";
const router = useRoute();
const routerPush = useRouter();
import { langWeb } from "../../stores/lang";
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
import { DVHCStore } from "../../stores/getDVHC";
import dataSelect from "./dataForm-cau-hinh-tram";
import { profileUserStore } from "../../stores/profileUser";
const profileUser = profileUserStore();
import LanguageService from "../../service/LanguageService";
const allProtocol = dataSelect.protocol;
const dvhc_store = DVHCStore();
const loadingBtn = ref(false);
const validateFlag = ref(false);
const showOptionDB = ref(false);
const inputLocation = ref(undefined);
const districtDataFindId = ref([]);
const dataForm = reactive({
  value: {
    province_id: undefined,
    district_id: undefined,
    net_x: undefined,
    url: "",
    type: "",
    db_connection_string: "",
    db_connection: {
      server: "",
      database_name: "",
      username: "",
      password: "",
      protocol: "",
      port: "",
    },
    request_server_info: {
      ip: "",
      port_socket: "",
      port_ftp: "",
      ftp_username: "",
      ftp_password: "",
    },
    loc: {
      type: "",
      coordinates: [105.8440129, 21.0109351], //default positon
    },
    columns: {},
    coordinatesFromMoveMap: [],
  },
});
const probeActive = ref(true);
const allNetX = ref([]); // net_x list
const isReadOnly = ref(true);
const checkShowOptionDB = ref(false);
const validateProtocol = (rule, value, callback) => {
  if (value === "") {
    callback(new Error(t("valid_required")));
  } else if (value === "selectProtocol") {
    callback(new Error(t("t_protocol_wrong")));
  } else callback();
};
const rulesForm = reactive({
  province_id: [Validate.requiredChange],
  district_id: [Validate.requiredChange],
  net_x: [Validate.requiredChange],
  type: [Validate.requiredChange],
  db_connection: {
    server: [Validate.required, Validate.checkServer],
    database_name: [Validate.required, Validate.checkDatabaseName],
    username: [Validate.required],
    password: [Validate.required, Validate.checkMinLength(2)],
    protocol: [{ validator: validateProtocol, trigger: "change" }],
  },
  request_server_info: {
    ip: [Validate.required],
    port_socket: [Validate.required, Validate.checkNumber],
    port_ftp: [Validate.required, Validate.checkNumber],
    ftp_username: [Validate.required],
    ftp_password: [Validate.required],
  },
  url: [Validate.required, Validate.checkURL],
});
const listType = ["probe", "bras"];
const rolesUser = computed(() => {
  return profileUser.roles;
});
const columnProbe = ref([]);
const vietnamBounds = {
  north: 23.3925, // Điểm cực Bắc
  south: 8.559, // Điểm cực Nam
  west: 102.144, // Điểm cực Tây
  east: 109.464, // Điểm cực Đông
};
const isPointInVietnam = (toaDo) => {
  return (
    toaDo[1] <= vietnamBounds.north &&
    toaDo[1] >= vietnamBounds.south &&
    toaDo[0] >= vietnamBounds.west &&
    toaDo[0] <= vietnamBounds.east
  );
};
const addNewProbe = (formEl) => {
  validateFlag.value = true;
  formEl.validate(async (valid) => {
    if (!valid) {
      toastr.warning(t("t_fields_wrong"));
      loadingBtn.value = false;
      return;
    }

    loadingBtn.value = true;
    validateFlag.value = true;
    const probe_object = {
      disabled: !probeActive.value,
      province_id: dataForm.value.province_id,
      district_id: dataForm.value.district_id,
      net_x: dataForm.value.net_x.trim(),
      url: dataForm.value.url.trim(),
      type: dataForm.value.type,
      db_connection_string: null,
      db_connection: {
        server: dataForm.value.db_connection.server.trim(),
        port:
          dataForm.value.db_connection.port?.length > 0
            ? dataForm.value.db_connection.port
            : null,
        database_name: dataForm.value.db_connection.database_name.trim(),
        username: checkShowOptionDB.value
          ? dataForm.value.db_connection.username
          : null,
        password: checkShowOptionDB.value
          ? dataForm.value.db_connection.password
          : null,
        protocol: dataForm.value.db_connection.protocol,
      },
      request_server_info: {
        ip: dataForm.value.request_server_info.ip,
        port_socket: dataForm.value.request_server_info.port_socket,
        port_ftp: dataForm.value.request_server_info.port_ftp,
        ftp_username: dataForm.value.request_server_info.ftp_username,
        ftp_password:
          dataForm.value.request_server_info?.ftp_password ?? undefined,
      },
      loc: {
        type: "Point",
        coordinates:
          dataForm.value.coordinatesFromMoveMap?.length > 0
            ? dataForm.value.coordinatesFromMoveMap
            : dataForm.value.loc.coordinates,
      },
    };
    if (
      dataForm.value.db_connection.password &&
      dataForm.value.db_connection.password.length > 0
    ) {
      probe_object.db_connection.password =
        dataForm.value.db_connection.password;
    }
    if (!isPointInVietnam(probe_object.loc.coordinates)) {
      toastr.warning(t("t_select_VN"));
      loadingBtn.value = false;
      return;
    }
    try {
      const newId = await mushroom.probe.createAsync(probe_object);
      if (newId.result) {
        let column = ref({});
        if (columnProbe.value) {
          columnProbe.value.forEach((item) => {
            column.value[item.code] = dataForm.value.columns[item.code];
          });
        }
        let data_update = {
          id: newId.result,
          columns: column.value,
        };
        await mushroom.probe.update_probe_columnsAsync({
          body: data_update,
        });

        toastr.success(t("toastr_add_success"));
        setTimeout(() => {
          routerPush.push({ name: "default" });
        }, 1500);
      }
    } catch (e) {
      MethodService.showError(e.code);
    } finally {
      loadingBtn.value = false;
    }
  });
};
const addUpdateProbe = (formEl) => {
  loadingBtn.value = true;
  validateFlag.value = true;
  formEl.validate(async (valid) => {
    if (!valid) {
      toastr.warning(t("t_fields_wrong"));
      loadingBtn.value = false;
      return;
    }

    if (rolesUser.value === "Admin" || rolesUser.value === "AdminPrimary") {
      const probe_object = {
        id: router.params.id,
        disabled: !probeActive.value,
        province_id: dataForm.value.province_id,
        district_id: dataForm.value.district_id,
        net_x: dataForm.value.net_x.trim(),
        url: dataForm.value.url.trim(),
        type: dataForm.value.type,
        db_connection_string: null,
        db_connection: {
          server: dataForm.value.db_connection.server.trim(),
          port:
            dataForm.value.db_connection.port?.length > 0
              ? dataForm.value.db_connection.port
              : null,
          database_name: dataForm.value.db_connection.database_name.trim(),
          username: checkShowOptionDB.value
            ? dataForm.value.db_connection.username
            : null,
          password:
            dataForm.value.db_connection.password &&
            dataForm.value.db_connection.password.length > 0
              ? dataForm.value.db_connection.password
              : undefined,
          protocol: dataForm.value.db_connection.protocol,
        },
        request_server_info: {
          ip: dataForm.value.request_server_info.ip,
          port_socket: dataForm.value.request_server_info.port_socket,
          port_ftp: dataForm.value.request_server_info.port_ftp,
          ftp_username: dataForm.value.request_server_info.ftp_username,
          ftp_password:
            dataForm.value.request_server_info?.ftp_password ?? undefined,
        },
        loc: {
          type: "Point",
          coordinates:
            dataForm.value.coordinatesFromMoveMap?.length > 0
              ? dataForm.value.coordinatesFromMoveMap
              : dataForm.value.loc.coordinates,
        },
      };
      if (!checkShowOptionDB.value) probe_object.db_connection.password = null;
      if (!isPointInVietnam(probe_object.loc.coordinates)) {
        toastr.warning(t("t_select_VN"));
        loadingBtn.value = false;
        return;
      }
      try {
        const newId = await mushroom.probe.partialUpdateAsync(probe_object);
        if (newId.result) {
          updateProbeColumn();
        }
      } catch (e) {
        MethodService.showError(e.code);
      } finally {
        loadingBtn.value = false;
      }
    } else {
      try {
        updateProbeColumn();
      } catch (e) {
        MethodService.showError(e.code);
      } finally {
        loadingBtn.value = false;
      }
    }
  });
};

const updateProbeColumn = async () => {
  let column = ref({});
  if (columnProbe.value) {
    columnProbe.value.forEach((item) => {
      column.value[item.code] = dataForm.value.columns[item.code];
    });
  }
  let data_update = {
    id: router.params.id,
    columns: column.value,
  };
  await mushroom.probe.update_probe_columnsAsync({
    body: data_update,
  });
  toastr.success(t("toastr_update_success"));
  routerPush.push({ name: "default" });
};

const getLabelForInput = (item) => {
  if (item?.title_lang?.[lang_web.showLang] === "") return item.title;
  return item?.title_lang?.[lang_web.showLang] ?? item.title;
};

const moveMap = (coordinates) =>
  (dataForm.value.coordinatesFromMoveMap = coordinates);
const get_probe_column_config = async () => {
  let filter = ref("");
  rolesUser.value === "AdminLocal"
    ? (filter.value = Filter.eq("permission.edit", true).build())
    : (filter.value = "");
  try {
    const response = await mushroom.probe_column_config.listAsync({
      filters: filter,
      sort: "index,title",
    });
    if (response.result) {
      columnProbe.value = response.result;
      response.result.forEach((item) => {
        dataForm.value[item.code] = "";
      });
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const get_data_detail_probe = async () => {
  try {
    const response = await mushroom.probe.findByIdAsync({
      id: router.params.id,
      fields:
        "id,disabled,province_id,district_id,net_x,url,loc,type,db_connection.server,db_connection.database_name,db_connection.port,db_connection.protocol,db_connection.username,columns,request_server_info.ip,request_server_info.port_socket,request_server_info.port_ftp,request_server_info.ftp_username",
    });

    if (!response.result.request_server_info) {
      response.result.request_server_info = {};
      response.result.request_server_info.ip = undefined;
      response.result.request_server_info.port_socket = undefined;
      response.result.request_server_info.port_ftp = undefined;
      response.result.request_server_info.ftp_username = undefined;
      response.result.request_server_info.ftp_password = undefined;
    }
    dataForm.value = response.result;
    if (dataForm.value.db_connection.username) {
      checkShowOptionDB.value = true;
      showOptionDB.value = true;
    }
    probeActive.value = !response.result.disabled;
    dataForm.value.loc.coordinates = JSON.parse(
      JSON.stringify(response.result.loc.coordinates)
    );
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const getSystemConfigUrl = async () => {
  try {
    const response = await mushroom.system_config.listAsync({
      filters: Filter.eq("key", "user_url").build(),
    });
    if (response.result.length > 0) {
      dataForm.value.url = response.result[0].value;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const get_all_net_X = async () => {
  try {
    const response = await mushroom.probe.views.get_net_xsAsync();
    allNetX.value = [];
    response.result?.forEach((item) => {
      allNetX.value.push({
        value: item.net_x,
        label: item.net_x,
      });
    });
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const removeReadOnly = () => (isReadOnly.value = false);
const showOptionDatabase = () => {
  showOptionDB.value = !showOptionDB.value;
  isReadOnly.value = true;
  dataForm.value.db_connection.username = "";
  dataForm.value.db_connection.password = "";
};
const changeProvince = async (idProvince) => {
  const provinceSelect = dvhc_store.provinceData.filter(
    (item) => item.value === idProvince
  );
  dataForm.value.columns["province"] = provinceSelect[0]?.label;
  dataForm.value.columns["district"] = "";
  dataForm.value.loc.coordinates = provinceSelect[0]?.center;
  dataForm.value.district_id = "";
  await getDistrictById(idProvince);
};
const changeDistrict = (idDistrict) => {
  const districtSelect = districtDataFindId.value.filter(
    (item) => item.value === idDistrict
  );
  dataForm.value.columns["district"] = districtSelect[0]?.label;
  dataForm.value.loc.coordinates = districtSelect[0]?.center;
};
const changeDepartment = (department) => {
  if (!department) {
    dataForm.value.columns["department"] = "";
  } else {
    allNetX.value.forEach((item) => {
      if (item.value == department) {
        dataForm.value.columns["department"] = item.label;
      } else {
        dataForm.value.net_x = department.trim();
        dataForm.value.columns["department"] = department.trim();
      }
    });
  }
};
const changeProtocol = (protocol) => {
  if (!protocol) return;
  dataForm.value.db_connection.protocol = protocol;
};
const changeType = (type) => {
  type
    ? (dataForm.value.columns["type"] = listType.filter(
        (item) => item === type
      )[0])
    : (dataForm.value.columns["type"] = "");
};
const changeStatus = (status) => {
  status
    ? (dataForm.value.columns["status"] = t("t_supervising"))
    : (dataForm.value.columns["status"] = t("t_not_supervising"));
};
const changeLocation = () => {
  if (!inputLocation.value) return;
  setTimeout(() => {
    let arraySplit = inputLocation.value.split(",");
    let arrayLoction = arraySplit.map((item) => (item = Number(item))) || [];

    if (
      arrayLoction.length === 2 &&
      arrayLoction.every((item) => typeof item === "number") &&
      arrayLoction[1] !== 0
    ) {
      dataForm.value.loc.coordinates = arrayLoction;
    }
  }, 1000);
};
const getDistrictById = async (id) => {
  try {
    const response = await mushroom.district.getAllAsync({
      fields: "id,disabled,code,name,provinceId,index,type,center,bdy",
      filters: Filter.eq("provinceId", id).build(),
    });
    if (response.result?.length > 0) {
      districtDataFindId.value = [];
      response.result.forEach((district) => {
        districtDataFindId.value.push({
          value: district?.id,
          label: district?.type + " " + district?.name,
          center: [district?.center?.lng, district?.center?.lat],
        });
      });
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const backHome = () => routerPush.push({ name: "default" });
watch(
  () => router.name,
  async (newRouterName) => {
    if (newRouterName === "ThemMoiTram") {
      await getSystemConfigUrl();
      dataForm.value.province_id = "";
      dataForm.value.district_id = "";
      dataForm.value.net_x = "";
      dataForm.value.type = "";
      dataForm.value.db_connection_string = "";
      dataForm.value.db_connection.server = "";
      dataForm.value.db_connection.username = "";
      dataForm.value.db_connection.password = "";
      dataForm.value.db_connection.database_name = "";
      dataForm.value.db_connection.protocol = "";
      dataForm.value.db_connection.port = "";
      dataForm.value.request_server_info.ftp_password = "";
      dataForm.value.request_server_info.ftp_username = "";
      dataForm.value.request_server_info.ip = "";
      dataForm.value.request_server_info.port_ftp = "";
      dataForm.value.request_server_info.port_socket = "";
      dataForm.value.loc.type = "";
      dataForm.value.loc.coordinates = [105.8440129, 21.0109351];
      dataForm.value.columns = {};
      dataForm.value.columns["status"] = t("t_supervising");
    }
  }
);

watch(
  () => lang_web.showLang,
  () => {
    lang.value = lang_web.showLang.toLocaleUpperCase();
    LanguageService.lang = LanguageService[lang.value];
    if (validateFlag.value === true) {
      rulesForm.value = MethodService.copyObject(rulesForm);
    }
    get_probe_column_config();
  }
);

onMounted(async () => {
  ruleFormRef.value.resetFields();
  await dvhc_store.getProvince();
  await get_probe_column_config();
  if (rolesUser.value === "Admin" || rolesUser.value === "AdminPrimary") {
    await get_all_net_X();
  }
  if (router.name === "ThemMoiTram") {
    await getSystemConfigUrl();
    dataForm.value.columns["status"] = t("t_supervising");
  }
  if (router.name === "CapNhatTram") {
    await get_data_detail_probe();
    await getDistrictById(dataForm.value.province_id);
    probeActive.value
      ? (dataForm.value.columns["status"] = t("t_supervising"))
      : (dataForm.value.columns["status"] = t("t_not_supervising"));
  }
});
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-header">
        <h3 v-if="router.name === 'ThemMoiTram'">
          {{ $t("t-add-probe") }}
        </h3>
        <h3 v-if="router.name === 'CapNhatTram'">{{ $t("t-update-probe") }}</h3>
      </div>
      <div class="card-body">
        <el-form
          ref="ruleFormRef"
          :model="dataForm.value"
          :rules="rulesForm"
          label-width="200px"
          class="form-user"
          autocomplete="off"
        >
          <div v-if="rolesUser === 'Admin' || rolesUser === 'AdminPrimary'">
            <el-form-item :label="$t('t-province')" prop="province_id">
              <el-select
                v-model="dataForm.value.province_id"
                filterable
                :placeholder="$t('t-choose-province')"
                style="width: 100%"
                @change="changeProvince"
              >
                <el-option
                  v-for="item in dvhc_store.provinceData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('t-district')">
              <el-select
                v-model="dataForm.value.district_id"
                filterable
                :placeholder="$t('t-choose-district')"
                style="width: 100%"
                @change="changeDistrict"
              >
                <el-option
                  v-for="item in districtDataFindId"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item :label="$t('t-list-netX')" prop="net_x">
              <el-select
                v-model="dataForm.value.net_x"
                filterable
                allow-create
                default-first-option
                :reserve-keyword="false"
                :placeholder="$t('t-place-list-netX')"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
                @change="changeDepartment"
              >
                <el-option
                  v-for="item in allNetX"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <div class="d-flex justify-content-between">
              <el-form-item :label="$t('t-string-db')" required class="w-100">
                <div class="w-100 db-connection">
                  <div class="d-flex">
                    <el-form-item
                      prop="db_connection.server"
                      class="w-100 db-connection_group"
                      style="margin-bottom: 25px"
                    >
                      <div class="box-db w-100">
                        <span>Server/IP</span>
                        <el-input
                          type="text"
                          autocomplete="off"
                          clearable
                          v-model="dataForm.value.db_connection.server"
                          :placeholder="$t('t-server/ip')"
                          class="input-db"
                        />
                      </div>
                    </el-form-item>
                    <el-form-item
                      prop="db_connection.database_name"
                      class="w-100"
                      style="margin-bottom: 25px"
                    >
                      <div class="box-db w-100">
                        <span>Database</span>
                        <el-input
                          type="text"
                          autocomplete="off"
                          clearable
                          v-model="dataForm.value.db_connection.database_name"
                          :placeholder="$t('t-database')"
                          class="input-db"
                        />
                      </div>
                    </el-form-item>
                  </div>
                  <div class="d-flex">
                    <el-form-item
                      prop="db_connection.port"
                      class="w-100 db-connection_group"
                    >
                      <div class="box-db w-100">
                        <span>Port</span>
                        <el-input
                          type="text"
                          autocomplete="off"
                          clearable
                          v-model="dataForm.value.db_connection.port"
                          :placeholder="$t('t-port')"
                          class="input-db"
                        />
                      </div>
                    </el-form-item>
                    <el-form-item prop="db_connection.protocol" class="w-100">
                      <div class="box-db w-100">
                        <span>Protocol</span>
                        <el-select
                          v-model="dataForm.value.db_connection.protocol"
                          filterable
                          :no-match-text="$t('t-match-data')"
                          :no-data-text="$t('t-no-data')"
                          clearable
                          placeholder="Protocol"
                          class="db-connection_protocol"
                          @change="changeProtocol"
                        >
                          <el-option
                            v-for="item in allProtocol"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </div>
                    </el-form-item>
                  </div>
                </div>
              </el-form-item>
            </div>
            <el-form-item>
              <el-checkbox
                v-model="checkShowOptionDB"
                :label="$t('t-add-option-db')"
                @change="showOptionDatabase"
              />
            </el-form-item>
            <Transition name="fade">
              <el-form-item v-if="showOptionDB">
                <div class="d-flex w-100">
                  <el-form-item
                    prop="db_connection.username"
                    class="w-100"
                    style="margin-right: 10px"
                  >
                    <div class="box-db w-100">
                      <span>User</span>
                      <el-input
                        type="text"
                        autocomplete="off"
                        clearable
                        :readonly="isReadOnly"
                        @click="removeReadOnly()"
                        v-model="dataForm.value.db_connection.username"
                        :placeholder="$t('t-place-usernameDB')"
                        class="input-db"
                      />
                    </div>
                  </el-form-item>
                  <!-- showOptionDB.value && -->
                  <div class="d-flex w-100">
                    <el-form-item
                      v-if="router.name === 'ThemMoiTram'"
                      :prop="showOptionDB ? 'db_connection.password' : ''"
                      class="w-100"
                    >
                      <div class="box-db w-100">
                        <span>Password</span>
                        <el-input
                          type="password"
                          autocomplete="off"
                          show-password
                          clearable
                          :readonly="isReadOnly"
                          @click="removeReadOnly()"
                          v-model="dataForm.value.db_connection.password"
                          :placeholder="`${
                            router.name == 'ThemMoiTram'
                              ? $t('t-place-passwordDB')
                              : $t('t-place-space-dont-change')
                          }`"
                          class="input-db"
                        />
                      </div>
                    </el-form-item>

                    <el-form-item
                      v-if="router.name === 'CapNhatTram'"
                      class="w-100"
                    >
                      <div class="box-db w-100">
                        <span>Password</span>
                        <el-input
                          type="password"
                          autocomplete="off"
                          show-password
                          clearable
                          :readonly="isReadOnly"
                          @click="removeReadOnly()"
                          v-model="dataForm.value.db_connection.password"
                          :placeholder="`${
                            router.name == 'ThemMoiTram'
                              ? $t('t-place-passwordDB')
                              : $t('t-place-space-dont-change')
                          }`"
                          class="input-db"
                        />
                      </div>
                    </el-form-item>
                  </div>
                </div>
              </el-form-item>
            </Transition>

            <el-form-item
              :label="$t('t-type-probe-bras')"
              :prop="router.name === 'ThemMoiTram' ? 'type' : ''"
            >
              <el-select
                v-model="dataForm.value.type"
                filterable
                :placeholder="$t('t-place-type-probe-bras')"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
                @change="changeType"
              >
                <el-option
                  v-for="item in listType"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="URL" prop="url">
              <el-input
                type="text"
                autocomplete="off"
                clearable
                v-model="dataForm.value.url"
                :placeholder="$t('t-place-url')"
              />
            </el-form-item>
            <el-form-item
              :label="$t('t-request_server_info')"
              required
              class="w-100"
            >
              <div class="d-flex w-100">
                <el-form-item
                  prop="request_server_info.ip"
                  class="w-100"
                  style="margin-right: 10px"
                >
                  <div class="box-db w-100">
                    <span>Ip_Socket</span>
                    <el-input
                      type="text"
                      autocomplete="off"
                      clearable
                      v-model="dataForm.value.request_server_info.ip"
                      :placeholder="$t('t-ip_socket')"
                      class="input-db"
                    />
                  </div>
                </el-form-item>
                <el-form-item
                  prop="request_server_info.port_socket"
                  class="w-100"
                  style="margin-right: 10px"
                >
                  <div class="box-db w-100">
                    <span>Port_Socket</span>
                    <el-input
                      type="text"
                      autocomplete="off"
                      clearable
                      v-model="dataForm.value.request_server_info.port_socket"
                      :placeholder="$t('t-port_socket')"
                      class="input-db"
                    />
                  </div>
                </el-form-item>
                <el-form-item prop="request_server_info.port_ftp" class="w-100">
                  <div class="box-db w-100">
                    <span>Port_FPT</span>
                    <el-input
                      type="text"
                      autocomplete="off"
                      clearable
                      v-model="dataForm.value.request_server_info.port_ftp"
                      :placeholder="$t('t-port_ftp')"
                      class="input-db"
                    />
                  </div>
                </el-form-item>
              </div>
              <div class="d-flex w-100" style="margin-top: 25px">
                <el-form-item
                  prop="request_server_info.ftp_username"
                  class="w-100"
                  style="margin-right: 10px"
                >
                  <div class="box-db w-100">
                    <span>Account</span>
                    <el-input
                      type="text"
                      autocomplete="off"
                      clearable
                      v-model="dataForm.value.request_server_info.ftp_username"
                      :placeholder="$t('t-ftp_username')"
                      class="input-db"
                    />
                  </div>
                </el-form-item>
                <el-form-item
                  class="w-100"
                  :prop="
                    router.name === 'ThemMoiTram'
                      ? 'request_server_info.ftp_password'
                      : ''
                  "
                >
                  <div class="box-db w-100">
                    <span>Password</span>
                    <el-input
                      type="password"
                      autocomplete="off"
                      clearable
                      show-password
                      v-model="dataForm.value.request_server_info.ftp_password"
                      class="input-db"
                      :readonly="isReadOnly"
                      @click="removeReadOnly()"
                      :placeholder="`${
                        router.name == 'ThemMoiTram'
                          ? $t('t-ftp_password')
                          : $t('t-place-space-dont-change')
                      }`"
                    />
                  </div>
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item
              ><el-checkbox
                v-model="probeActive"
                :label="$t('t-probe-active')"
                @change="changeStatus"
            /></el-form-item>
            <el-form-item :label="$t('t-location-map')">
              <div class="marker-custom"></div>
              <el-input
                v-model="inputLocation"
                :prefix-icon="Location"
                placeholder="ex: 105.50, 10.50"
                class="input-location"
                @input="changeLocation"
              ></el-input>
              <map-component
                :centerMap="dataForm.value.loc.coordinates"
                @moveMap="moveMap"
              >
              </map-component>
            </el-form-item>
            <hr />
          </div>

          <h3 class="card-title mb-4">
            {{ $t("t-option-value-field") }}
          </h3>
          <el-form-item
            v-for="item in columnProbe"
            :key="item.id"
            :label="getLabelForInput(item)"
          >
            <el-input
              type="text"
              autocomplete="off"
              v-model="dataForm.value.columns[item.code]"
              :placeholder="`${$t('t-please-enter')} ${getLabelForInput(item)}`"
              :disabled="
                (!item.permission.edit &&
                  router.name === 'CapNhatTram' &&
                  rolesUser !== 'Admin') ||
                item.code === 'type' ||
                item.code === 'status'
              "
            />
          </el-form-item>
          <div class="text-center">
            <button
              type="button"
              class="btn btn-soft-secondary btn-border mx-3"
              @click="backHome"
            >
              {{ $t("t-back") }}
            </button>
            <el-button
              type="button"
              class="btn btn-secondary btn-border"
              style="height: 38px"
              @click="addNewProbe(ruleFormRef)"
              v-if="router.name === 'ThemMoiTram'"
              :loading="loadingBtn"
            >
              {{ $t("t-add") }}
            </el-button>
            <el-button
              type="button"
              class="btn btn-secondary btn-border"
              style="height: 38px"
              @click="addUpdateProbe(ruleFormRef)"
              v-if="router.name === 'CapNhatTram'"
              :loading="loadingBtn"
            >
              {{ $t("t-update") }}
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
  </Layout>
</template>

<style scoped lang="scss">
.map-container {
  position: relative;
  height: 500px;
}

.map-marker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 40px;
  color: rgb(255, 95, 95);
}

.input-db {
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.box-db {
  display: flex;
  box-shadow: 0 0 0 1px #dcdfe6;
  border-radius: 5px;
  padding: 0 5px;
  align-items: center;
}

.box-db :deep .el-input__wrapper {
  box-shadow: none !important;
}

.box-db span {
  background-color: #151529c2;
  font-size: 12px;
  border-radius: 5px;
  color: #fff;
  padding: 3px;
  height: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
}

:deep .db-connection {
  &_group {
    margin-right: 10px;
  }

  .el-select .el-select__wrapper {
    box-shadow: none;
  }
}
</style>
